<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
				<v-col cols="12" sm="5">
					<social :meta="meta" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<p class="blockquote">
						A neuropsychological assessment involves the evaluation
						of a child's thinking, behaviour and problem-solving
						skills (i.e. their cognitive development). This involves
						the child completing a range of paper and pencil tasks,
						solving practical problems, and answering questions.
						Additional information may also be gathered from
						parents, teachers and other health care providers. The
						child's performance is compared to other children of the
						same age and education level and, from this information,
						an individual pattern of thinking and learning strengths
						and weaknesses is defined. This information is then used
						to guide intervention strategies at home, in school, and
						in the community.
					</p>
				</v-col>
				<v-col cols="12" sm="6" lg="5">
					<p>A neuropsychological assessment will provide you with:</p>
					<ul>
						<li>
							A description of your child's cognitive strengths
							and weaknesses.
						</li>
						<li>
							A clearer understanding of your child and how they
							learn.
						</li>
						<li>
							Recommendations for educational planning and support
							needs.
						</li>
						<li>
							Help in knowing what is fair to expect from your
							child at this point in time.
						</li>
						<li>
							Help in knowing what your child’s needs may be in
							the future.
						</li>
						<li>
							Practical recommendations to help your child
							function more effectively at school.
						</li>
						<li>Suggestions for improving your child's behaviour.</li>
						<li>
							Referral to other support services where required,
							such as a clinical psychologist, occupational,
							physical or speech therapist for ongoing help with
							your child's behaviour and development.
						</li>
					</ul>
				</v-col>
				<v-col v-if="$vuetify.breakpoint.lgAndUp" lg="2" />
				<v-col cols="12" sm="6" lg="5">
					<p>
						The assessment process generally involves a clinical
						interview with parents/caregivers, formal testing with a
						range of tasks, liaison with teachers, preparation of a
						detailed report, feedback and intervention planning if
						needed. The skills which are typically assessed are:
					</p>
					<ul>
						<li>General intellectual ability</li>
						<li>Academic ability</li>
						<li>Attention and concentration</li>
						<li>Learning and memory</li>
						<li>Language</li>
						<li>Executive functions</li>
						<li>Adaptive behaviours</li>
						<li>Social skills</li>
						<li>Emotional control</li>
					</ul>
				</v-col>
				<v-col cols="12">
					<h3>
						What can I expect during a Neuropsychological
						Assessment?
					</h3>
					<p>
						Not all neuropsychological assessments are the same;
						some are brief and others are
						<router-link to="/services/comprehensive-neuropsychology">
							comprehensive
						</router-link>. Assessments are individually tailored to the needs of
						each child. The assessment can usually be conducted in
						one day and may take between 2 to 4 hours (including
						sufficient breaks) depending of the age and abilities of
						the child. It may include:
					</p>
					<ul>
						<li>
							A comprehensive interview (up to 1 hour) with
							parents to gather information about the child's
							development and any parental concerns with respect
							to cognitive, social emotional or academic progress.
						</li>
						<li>
							The child working one on one (2-4 hours) with the
							neuropsychologist through a battery of activities to
							measure a range of cognitive skills.
						</li>
						<li>
							A feedback session (up to 1 hour) to review
							assessment findings and discuss treatment
							recommendations.
						</li>
						<li>
							A formal report is written and provided to family
							and other medical professionals.
						</li>
						<li>
							Feedback with the child's school can be organised on
							request.
						</li>
					</ul>
				</v-col>
			</v-row>
		</v-container>
		<v-lazy
			v-model="servicesVisible"
			:min-height="parallax.height"
			:options="{
				threshold: 0.1
			}"
			transition="slide-y-transition"
		>
			<parallax-image :alt="parallax.alt" :height="parallax.height">
				<v-container>
					<v-row justify="center">
						<v-col cols="12">
							<h2 class="service-heading">
								Our Services
							</h2>
						</v-col>
						<v-col
							v-for="service in services"
							:key="service.class"
							cols="12"
							sm="6"
							md="4"
							xl="2"
						>
							<services-card
								:modifier="service.class"
								:anchor="service.anchor"
								:icon="service.icon"
								:title="service.title"
								:description="service.description"
								:cta="service.cta"
							/>
						</v-col>
					</v-row>
				</v-container>
			</parallax-image>
		</v-lazy>
	</article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
import ParallaxImage from '@/components/image/ParallaxImage';
import ServicesCard from '@/components/card/ServicesCard';
export default {
	name: 'Services',
	components: {
		Breadcrumbs,
		HeroImage,
		ParallaxImage,
		ServicesCard,
		Social
	},
	mixins: [_MxAppViewClass, _MxMeta],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: `${this.$route.name} & Assessments`,
				imgSmall: 'hero-08-small',
				imgLarge: 'hero-08-large'
			},
			meta: {
				description:
					'Sydney Kids Neuropsychology offers a range of neuropsychological services and assessments for your child or adolescent.',
				title:
					'Child & Adolescent Neuropsychology Services and Assessments'
			},
			parallax: {
				alt: 'Sydney Kids Neuropsychology',
				height: null
			},
			services: [
				{
					class: 'service-card--autism',
					anchor: '/services/autism-spectrum-disorder',
					icon: '$vuetify.icons.autism',
					title: 'Autism Spectrum Disorder',
					description:
						'Determine if your child has Autism Spectrum Disorder.',
					cta: 'read more'
				},
				{
					class: 'service-card--intellectual',
					anchor: '/services/intellectual-functioning',
					icon: '$vuetify.icons.intellectual',
					title: 'Intellectual Functioning',
					description:
						'An IQ assessment can help to ascertain cognitive strengths and weaknesses.',
					cta: 'read more'
				},
				{
					class: 'service-card--school',
					anchor: '/services/school-readiness',
					icon: '$vuetify.icons.school',
					title: 'School Readiness',
					description:
						'Help determine if your child is intellectually and emotionally ready to start school.',
					cta: 'read more'
				},
				{
					class: 'service-card--academic',
					anchor: '/services/academic-assessment',
					icon: '$vuetify.icons.academic',
					title: 'Academic Assessment',
					description:
						'Help support your childs learning with an Academic Assessment.',
					cta: 'read more'
				},
				{
					class: 'service-card--attention',
					anchor: '/services/attention-difficulties',
					icon: '$vuetify.icons.attention',
					title: 'Attention Difficulties',
					description:
						'Establish whether your child has an underlying attention deficit disorder.',
					cta: 'read more'
				},
				{
					class: 'service-card--comprehensive',
					anchor: '/services/comprehensive-neuropsychology',
					icon: '$vuetify.icons.comprehensive',
					title: 'Comprehensive Neuropsychology',
					description:
						'Examine domains of intellectual and cognitive functioning tailored to your child.',
					cta: 'read more'
				}
			],
			servicesVisible: false
		};
	},
	created() {
		this.calcParallaxHeight();
	},
	methods: {
		calcParallaxHeight() {
			if (this.$vuetify.breakpoint.xsOnly) {
				this.parallax.height = 2433;
			}
			if (this.$vuetify.breakpoint.smOnly) {
				this.parallax.height = 1331;
			}
			if (this.$vuetify.breakpoint.mdAndUp) {
				this.parallax.height = 968;
			}
			if (this.$vuetify.breakpoint.xlOnly) {
				this.parallax.height = 605;
			}
		}
	}
};
</script>

<style scoped lang="scss">
.service-heading {
	color: #111;
	font-family: $heading-font-family;
	font-size: $font-size-root * 1.777;
	font-weight: map-get($font-weights, 'regular');
	line-height: $line-height-sm;
	margin-bottom: gutter-size(5);
	text-align: center;
	text-transform: uppercase;
}
</style>
